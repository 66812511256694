<template>
  <div>
    <slot name="subHeader"> </slot>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Type
            </th>
            <th class="text-left">
              Id
            </th>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="record in recordList"
            :key="record.id"
            :class="{ highlighted: isSelected(record) }"
          >
            <td>
              <record-badge-button :record="record">
                <template v-slot:tooltip> </template>
              </record-badge-button>
            </td>
            <td>{{ record.id }}</td>
            <td>{{ fullRecordName(record) }}</td>
            <td>
              <span>{{ getRecordOperationStatus(record) }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn v-bind="attrs" light color="error" icon v-on="on">
                    <v-icon :color="getIconColor(record)">
                      {{ getIcon(record) }}</v-icon
                    >
                  </v-btn>
                </template>

                <span>{{ getRecordOperationMessage(record) }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { fullRecordName } from "@/services/record/recordService";
import { iconInfo } from "@/design/icon/iconConst";
import { findBatchRecordStatus } from "@/model/record/recordModel";

export default {
  name: "BatchRecordsTable",
  components: {
    RecordBadgeButton: () => import("@/components/record/RecordBadgeButton")
  },
  props: {
    recordList: {
      type: Array,
      required: true
    },
    selectedRecord: undefined,
    recordsStatus: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isSelectedRecord: null,
      fullRecordName: fullRecordName,
      iconInfo: iconInfo
    };
  },
  computed: {},

  methods: {
    /**
     * is Selected Record
     * @param record
     * @return {boolean}
     */
    isSelected(record) {
      return record === this.selectedRecord;
    },

    /**
     * Get Record Operation Status
     * @param record
     * @return {*}
     */
    getRecordOperationStatus(record) {
      return this.recordsStatus?.find(item => item.recordId === record?.id)
        ?.status;
    },

    /**
     * Get Record Operation Message
     * @param record
     * @return {*}
     */
    getRecordOperationMessage(record) {
      return this.recordsStatus?.find(item => item.recordId === record?.id)
        ?.message;
    },

    /**
     * Get Icon Color
     * @param record
     * @return {string}
     */
    getIconColor(record) {
      return findBatchRecordStatus(this.getRecordOperationStatus(record))
        ?.iconColor;
    },

    /**
     * Get Icon
     * @param record
     * @return {string}
     */
    getIcon(record) {
      return findBatchRecordStatus(this.getRecordOperationStatus(record))?.icon;
    }
  }
};
</script>

<style>
.highlighted {
  background-color: #b3e5fc; /* Indigo color */
}
</style>
